<template>
  <validation-observer ref="addTenantForm" #default="{ invalid }">
    <div class="d-flex justify-content-center" v-if="loadingId">
      <b-spinner variant="primary"> </b-spinner>
    </div>
    <b-form @submit.prevent="updateTenant" class="mx-1" v-if="!loadingId">
      <b-row>
        <b-col md="12">
          <b-form-group label="Company Id (No spaces)" label-for="login-id">
            <validation-provider
              #default="{ errors }"
              name="Company Id"
              vid="Company Id"
              rules="required"
            >
              <b-form-input
                id="login-id"
                v-model="tenantData.id"
                :state="errors.length > 0 ? false : null"
                name="login-name"
              readonly
                placeholder="Company Id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Name" label-for="login-name">
            <validation-provider
              #default="{ errors }"
              name="name"
              vid="name"
              rules="required"
            >
              <b-form-input
                id="login-name"
                v-model="tenantData.name"
                :state="errors.length > 0 ? false : null"
                name="login-name"
                placeholder="Company Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Email" label-for="login-email">
            <validation-provider
              #default="{ errors }"
              name="Email"
              vid="email"
              rules="required|email"
            >
              <b-form-input
                id="login-email"
                v-model="tenantData.adminEmail"
                :state="errors.length > 0 ? false : null"
                name="login-email"
                placeholder="john@example.com"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-checkbox
            v-for="item in modules"
            :key="item.id"
            :id="item.id"
            :name="item.name"
            v-model="item.isActive"
            @change="handleCheckboxChange(item.isActive, item.id)"
          >
            {{ item.name }}
          </b-form-checkbox>
        </b-col>

        <b-col cols="12">
          <b-card title="Upload your company logo">
            <b-media no-body>
              <b-media-aside>
                <b-link>
                  <b-img
                    ref="previewEl"
                    rounded
                    v-if="imageFile != null && imageFile.length != 0"
                    :src="imageFile"
                    height="80"
                  />
                </b-link>
                <!--/ avatar -->
              </b-media-aside>

              <b-media-body class="mt-75 ml-75">
                <!-- upload button -->
                <label
                  for="file-upload"
                  class="custom-file-upload text-white mb-75 mr-75"
                >
                  Upload
                </label>
                <input
                  id="file-upload"
                  type="file"
                  @change="showImagePreview($event)"
                  accept="image/.jpg, .jpeg"
                />

                <!-- reset -->
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  size="sm"
                  v-on:click="clearImage"
                >
                  Reset
                </b-button>
                <!--/ reset -->
                <b-card-text class="mx-3">Allowed JPG. Max size of 800kB</b-card-text>
              </b-media-body>
            </b-media>
          </b-card>

          <!--/ media -->
        </b-col>

        <b-col md="12">
          <b-form-group label="Company Address Line 1" label-for="login-Address">
            <validation-provider
              #default="{ errors }"
              name="Address Line 1"
              vid="Address Line 1"
              rules="required"
            >
              <b-form-input
                id="login-Address"
                v-model="tenantData.detailsDto.address"
                :state="errors.length > 0 ? false : null"
                name="addressLine1"
                placeholder="Address Line 1"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="City" label-for="login-City">
            <validation-provider
              #default="{ errors }"
              name="City"
              vid="City"
              rules="required"
            >
              <b-form-input
                id="login-City"
                v-model="tenantData.detailsDto.city"
                :state="errors.length > 0 ? false : null"
                name="City"
                placeholder="City"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Country" label-for="login-Country">
            <validation-provider
              #default="{ errors }"
              name="Country"
              vid="Country"
              rules="required"
            >
              <b-form-input
                id="login-Country"
                v-model="tenantData.detailsDto.country"
                :state="errors.length > 0 ? false : null"
                name="Country"
                readonly
                placeholder="Country"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
          >
            <b-spinner small v-if="loading"> </b-spinner>
            Edit
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BSpinner,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BLink,
  BCard,
  BFormSelect,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { mapActions, mapGetters } from "vuex";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import { mounted } from "vue-echarts";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BLink,
    BCard,
    BFormSelect,
  },
  props: {
    userData: {
      type: String,
    },
  },
  data() {
    return {
      isActive: [],
      imageSrc: "",
      // validation rules
      modules: [
        {
          isActive: false,
          name: "Sales Automation",
          id: "d5b79415-f733-4d35-ac94-4e94bc24edc8",
        },
        {
          isActive: false,
          name: "Inventory Management to Track and Monitor Stock",
          id: "5ed32efc-c009-47de-8529-661f8d23c412",
        },
        {
          isActive: false,
          name: "Production Management",
          id: "b5478194-8aac-4fef-893e-75d449ddc237",
        },
        {
          isActive: false,
          name: "Enterprise Marketing Management",
          id: "42911adf-89ae-4c6a-b4e1-c320793ad87f",
        },
        {
          isActive: false,
          name: "User Management",
          id: "0cf6c523-663f-4646-9b6c-80081320c936",
        },
      ],
      selectedModules: [],
      required,
      email,

      //data
      name: "",
      adminEmail: "",
      TenantModules: [],
      id: [],
      error: "",
      profileFile: null,
      imageFile: null,
      input: null,
      isImageUploading: false,
      addressLine1: "",
      City: "",
      country: "",
      tenantData: [],
    };
  },
  directives: {
    Ripple,
  },

  computed: {
    ...mapGetters("tenantModule", {
      tenantId: "tenantsId",
      loadingId: "loading",
    }),
  },
  methods: {
    success() {
      this.$swal({
        title: "Success",
        text: "Tenant updated Successful",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    showImagePreview(event) {
      this.input = event.target;
      if (this.input.files && this.input.files[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.imageFile = e.target.result;
        };
        reader.readAsDataURL(this.input.files[0]);
      }
    },
    error(data) {
      let msg = null;
      if (data) msg = this.getErrorString(data);
      this.$swal({
        title: "Error!",
        //text: msg ?? ' Failed!',
        html: msg ?? " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    getErrorString(data) {
      let msg = null;
      if (data.errors) {
        let errors = data.errors;
        msg = "<div>";
        msg += "<p>Cannot post your request due to the following errors:</p>";
        for (var prop in errors) {
          if (Object.prototype.hasOwnProperty.call(errors, prop) && prop !== "request") {
            msg += "<p>";
            msg += prop.replace("$.", "");
            msg += ": ";
            msg += errors[prop].toString().replace("$.", "");
            msg += "</p>";
          }
        }
        msg += "</div>";
      } else if (data.message) {
        msg = "<div>";
        msg += "<p>Cannot post your request due to the following errors:</p>";
        msg += data.message;
        msg += "</div>";
      }
      return msg;
    },

    uploadImage() {
      this.isImageUploading = true;
      setTimeout(() => {
        console.log(this.imageFile);
        this.isImageUploading = false;
        this.clearImage();
        alert("Image uploaded sucessfully!");
      }, 3000);
    },
    clearImage() {
      this.imageFile = null;
      this.input = null;
    },
    ...mapActions("tenantModule", ["updateTenantAction", "getTenantIdAction"]),
    handleCheckboxChange(isActive, id) {
      if (isActive) {
        const module = this.modules.find((item) => item.id === id);
        this.selectedModules.push({
          isActive: true,
          name: module.name,
          id: module.id,
        });
      } else {
        const index = this.selectedModules.findIndex((item) => item.id === id);
        this.selectedModules.splice(index, 1);
      }
      // Call your API here and pass `this.selectedModules` as the payload
    },
    updateTenant() {
      this.$refs.addTenantForm.validate().then((success) => {
        if (success) {
          let payload = {
            name: this.tenantData.name,
            adminEmail: this.tenantData.adminEmail,
            id: this.tenantData.id,
            connectionString: "",
            issuer: null,
            modules: this.selectedModules,
            logo: this.imageFile,
            address: this.tenantData.detailsDto.address,
            city: this.tenantData.detailsDto.city,
            country: this.tenantData.detailsDto.country,
          };
          this.updateTenantAction(payload)
            .then((response) => {
              this.success();
              this.$bvModal.hide("modal-edit");
            })
            .catch((e) => {
              this.error(e.response.data);
              this.$bvModal.hide("modal-edit");
              console.log(error.response.data);
            });
        }
      });
    },
  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
  async mounted() {
    await this.getTenantIdAction(this.userData).then((response) => {
      this.tenantData = this.tenantId;
      this.imageFile = "data:image/jpeg;base64," + this.tenantId.detailsDto.logo;
      this.modules.forEach((module) => {
        if (this.tenantData.modules.some((m) => m.id === module.id)) {
          module.isActive = true;
          this.selectedModules.push({
            isActive: true,
            name: module.name,
            id: module.id,
          });
        }
      });
    });
  },
};
</script>
<style scoped>
input[type="file"] {
  display: none;
}

.custom-file-upload {
  padding: 5px 11px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #9fc54c;
}
</style>
