<template>
  <b-row>
    <b-col cols="12">
      <div class="py-2 d-flex justify-content-end">
        <b-button variant="primary" v-b-modal.modal-lg> Add Company </b-button>
      </div>
    </b-col>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50">Per page</label>
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
          class="w-50"
        />
      </b-form-group>
    </b-col>

    <b-col md="10" class="my-1">
      <b-form-group
        label="Filter"
        label-cols-sm="8"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="tenants"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template v-slot:cell(isActive)="data">
          <b-form-checkbox
            v-model="data.item.isActive"
            :id="data.item.id"
            @change="updateStatus(data.item.id, data.item.isActive)"
          ></b-form-checkbox>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button
            variant="primary"
            size="sm"
            class="mr-50"
            v-b-modal.modal-edit
            @click="getIdOfTenant(data.item.id)"
          >
            Edit
          </b-button>
        </template>
      </b-table>
      <div class="d-flex justify-content-center mb-1">
        <b-spinner
          variant="success"
          label="Spinning"
          block
          v-if="loadingOkay"
          style="width: 3rem; height: 3rem"
        ></b-spinner>
      </div>
    </b-col>

    <b-col cols="12">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
      <b-form-group>
      Showing {{ perPage * (currentPage - 1) + 1}} 
      to {{ Math.min(perPage * currentPage, totalRows) }} 
      of {{ totalRows }} entries
        </b-form-group>
    </b-col>
    <b-modal
      id="modal-lg"
      ok-only
      ok-variant="secondary"
      ok-title="Cancel"
      centered
      size="lg"
      title="Add Company"
      hide
    >
      <AddTenant :module="this.module" @onchange="response" />
    </b-modal>
    <b-modal
      id="modal-edit"
      ok-only
      ok-variant="secondary"
      ok-title="Cancel"
      centered
      size="lg"
      title="Edit Company"
      hide
    >
      <EditTenant :userData="this.selectedButton" :module="this.module" />
    </b-modal>
  </b-row>
</template>

<script>
import AddTenant from "./AddTenantForm.vue";
import EditTenant from "./EditTenantForm.vue";
import { mapGetters, mapActions } from "vuex";

import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BSidebar,
  VBToggle,
  BFormCheckbox,
} from "bootstrap-vue";
import { BSpinner } from "bootstrap-vue";

export default {
  components: {
    BTable,
    AddTenant,
    BSpinner,
    BAvatar,
    EditTenant,
    BBadge,
    BRow,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  directives: {
    "b-toggle": VBToggle,
  },

  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 0,
      currentPage: 0,
      sortBy: "",
      selected: [],
      showSpinner: true,
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      error: "",
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      items: [],
      selectedButton: {},
      fields: [
        {
          key: "id",
          label: "Id",
        },

        { key: "name", label: "Company Name", sortable: true },
        "adminEmail",
        { key: "isActive", label: "Active", sortable: true },
        "actions",
      ],
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },

    ...mapGetters("tenantModule", {
      tenants: "tenants",
      loadingOkay: "loading",
    }),
    ...mapGetters("moduleModule", {
      module: "module",
    }),
  },

  methods: {
    ...mapActions("moduleModule", ["getModuleAction"]),
    ...mapActions("tenantModule", [
      "getTenantListAction",
      "deactivateTenantAction",
      "activateTenantAction",
    ]),

    response(response) {
      if (response) {
        this.getTenantListAction();
      }
    },
    updateStatus(id, status) {
      if (status) {
        this.activateTenantAction(id).then(() => {
          this.$bvToast.toast("Tenant Activated", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.getTenantListAction();
        });
      } else {
        this.deactivateTenantAction(id).then(() => {
          this.$bvToast.toast("Tenant Deactivated", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.getTenantListAction();
        });
      }
    },
    getIdOfTenant(name) {
      this.selectedButton = name;
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  async mounted() {
    await this.getModuleAction();
    await this.getTenantListAction()
      .then(() => {
        this.totalRows = this.tenants.length;
      })
      .catch((response) => {
        this.error = response;
      });
  },
};
</script>
