<template>
  <validation-observer ref="addTenantForm" #default="{ invalid }">
    <b-form @submit.prevent="addTenant" class="mx-1">
      <b-row>
        <b-col md="12">
          <b-form-group label="Company Id (No spaces)" label-for="login-id">
            <validation-provider #default="{ errors }" name="Company Id" vid="Company Id" rules="required">
              <b-form-input id="login-id" v-model="CompanyId" :state="errors.length > 0 ? false : null" name="login-name"
                placeholder="Company Id" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Company Name" label-for="login-name">
            <validation-provider #default="{ errors }" name="name" vid="name" rules="required">
              <b-form-input id="login-name" v-model="name" :state="errors.length > 0 ? false : null" name="login-name"
                placeholder="Company Name" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Email" label-for="login-email">
            <validation-provider #default="{ errors }" name="Email" vid="email" rules="required|email">
              <b-form-input id="login-email" v-model="adminEmail" :state="errors.length > 0 ? false : null"
                name="login-email" placeholder="john@example.com" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <b-form-checkbox :id="item.id" :name="item.name" v-for="item in modules" :key="item"
              @change="handleCheckboxChange(item.isActive, item.id)" class="my-1" v-model="item.isActive">
              {{ item.name }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-card title="Upload your company logo">
            <b-media no-body>
              <b-media-aside>
                <b-link>
                  <b-img ref="previewEl" rounded v-if="imageFile != null && imageFile.length != 0" :src="imageFile"
                    height="80" />
                </b-link>
                <!--/ avatar -->
              </b-media-aside>

              <b-media-body class="mt-75 ml-75">
                <!-- upload button -->
                <label for="file-upload" class="custom-file-upload text-white mb-75 mr-75">
                  Upload
                </label>
                <input id="file-upload" type="file" @change="showImagePreview($event)" accept="image/.jpg, .jpeg" />

                <!-- reset -->
                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" size="sm"
                  v-on:click="clearImage">
                  Reset
                </b-button>
                <!--/ reset -->
                <b-card-text class="mx-3">Allowed JPG. Max size of 800kB</b-card-text>
              </b-media-body>
            </b-media>
          </b-card>

          <!--/ media -->
        </b-col>

        <b-col md="12">
          <b-form-group label="Company Address Line 1" label-for="login-Address">
            <validation-provider #default="{ errors }" name="Address Line 1" vid="Address Line 1" rules="required">
              <b-form-input id="login-Address" v-model="addressLine1" :state="errors.length > 0 ? false : null"
                name="addressLine1" placeholder="Address Line 1" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="City" label-for="login-City">
            <validation-provider #default="{ errors }" name="City" vid="City" rules="required">
              <b-form-input id="login-City" v-model="City" :state="errors.length > 0 ? false : null" name="City"
                placeholder="City" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group label="Country" label-for="login-Country">
            <b-form-select v-model="selected" :options="country" />
          </b-form-group>

        </b-col>
        <b-col>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1"
            :disabled="invalid">
            <b-spinner small v-if="loading" />
            Submit
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BSpinner,
  VBToggle,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BLink,
  BFormSelect ,
  BCard,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect ,
    BFormCheckbox,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BLink,
    BCard,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      // validation rules
      required,
      email,
      CompanyId: "",
      addressLine1: "",
      City: "",
      country: "",
      profileFile: null,
      imageFile: null,
      input: null,
      isImageUploading: false,
      //data
      name: "",
      adminEmail: "",
      TenantModules: [],
      id: [],
      showSpinner: false,
      selected: "Mauritius",
      country: [
        { text: "Select Country", value: null },
        { text: "Mauritius", value: "Mauritius" },

      ],

      modules: [
        {
          isActive: false,
          name: "Sales Automation",
          id: "d5b79415-f733-4d35-ac94-4e94bc24edc8",
        },
        {
          isActive: false,
          name: "Inventory Management to Track and Monitor Stock",
          id: "5ed32efc-c009-47de-8529-661f8d23c412",
        },
        {
          isActive: false,
          name: "Production Management",
          id: "b5478194-8aac-4fef-893e-75d449ddc237",
        },
        {
          isActive: false,
          name: "Enterprise Marketing Management",
          id: "42911adf-89ae-4c6a-b4e1-c320793ad87f",
        },
        {
          isActive: false,
          name: "User Management",
          id: "0cf6c523-663f-4646-9b6c-80081320c936",
        },
      ],
      checkedValues: [],
    };
  },
  directives: {
    Ripple,
  },
  props: {
    token: {
      type: String,
    },

    module: {
      type: Array,
    },
  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
  computed: {
    ...mapGetters("tenantModule", {
      loading: "loading",
    }),
  },
  methods: {
    success() {
      this.$swal({
        title: "Success",
        text: "Tenant Registration Successful",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    error(data) {
      let msg = null;
      if (data) msg = this.getErrorString(data);
      this.$swal({
        title: "Error!",
        //text: msg ?? ' Failed!',
        html: msg ?? " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    getErrorString(data) {
      let msg = null;
      if (data.errors) {
        let errors = data.errors;
        msg = "<div>";
        msg += "<p>Cannot post your request due to the following errors:</p>";
        for (var prop in errors) {
          if (Object.prototype.hasOwnProperty.call(errors, prop) && prop !== "request") {
            msg += "<p>";
            msg += prop.replace("$.", "");
            msg += ": ";
            msg += errors[prop].toString().replace("$.", "");
            msg += "</p>";
          }
        }
        msg += "</div>";
      } else if (data.message) {
        msg = "<div>";
        msg += "<p>Cannot post your request due to the following errors:</p>";
        msg += data.message;
        msg += "</div>";
      }
      return msg;
    },
    ...mapActions("tenantModule", ["addTenantAction"]),

    handleCheckboxChange(event, id) {
      if (event) {
        this.checkedValues.push(id);
      } else {
        this.checkedValues.pop(id);
      }
    },
    showImagePreview(event) {
      this.input = event.target;
      if (this.input.files && this.input.files[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.imageFile = e.target.result;
        };
        reader.readAsDataURL(this.input.files[0]);
      }
    },
    uploadImage() {
      this.isImageUploading = true;
      setTimeout(() => {
        console.log(this.imageFile);
        this.isImageUploading = false;
        this.clearImage();
        alert("Image uploaded sucessfully!");
      }, 3000);
    },
    clearImage() {
      this.imageFile = null;
      this.input = null;
    },
    addTenant() {
      this.$refs.addTenantForm.validate().then((success) => {
        if (success) {
          let payload = {
            name: this.name,
            adminEmail: this.adminEmail,
            id: this.CompanyId,
            connectionString: "",
            issuer: null,
            moduleIds: this.checkedValues,
            logo: this.imageFile,
            address: this.addressLine1,
            city: this.City,
            country: this.selected,
          };

          this.addTenantAction(payload)
            .then((response) => {
              this.$bvModal.hide("modal-lg");
              this.$emit("onchange", true);
              this.success();
            })
            .catch((e) => {
              this.$bvModal.hide("modal-lg");
              this.error(e.response.data);
            });
        }
      });
    },
  },
};
</script>

<style scoped>
input[type="file"] {
  display: none;
}

.custom-file-upload {
  padding: 5px 11px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #9fc54c;
}
</style>
